<!--
 * @Author: your name
 * @Date: 2020-06-11 12:54:13
 * @LastEditTime: 2020-12-21 16:32:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \pcweb\src\views\myCenter\components\addressList.vue
--> 
<template>
  <div class="order-item">
    <div class="header">
      <span class="name">姓名</span>
      <span class="address">详细地址</span>
      <span class="phone">电话</span>
      <span class="operate">操作</span>
    </div>
    <div class="cotainer">
      <div class="addr-item" v-for="(item, index) in list" :key="index">
        <div class="name">{{ item.name }}</div>
        <div class="address">{{ item.city + '  ' + item.adress }}</div>
        <div class="phone">{{ item.mobile }}</div>
        <div class="operate">
         <span v-if="item.isclick == 1">默认地址</span>
         <span v-if="item.isclick == 0" @click="isclick(item.id)">设为默认地址</span>
          <span @click="operate('edit', item, index)">编辑</span>
          <span @click="operates('delete', item, index)">删除</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "OrderItem",
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    isclick(id){
      this.$emit('isclick',id)
    },
    operate(type, item, index) {
         this.$emit("operate", type, item, index);
    },
    operates(type, item, index) {
       let r = confirm('确定删除嘛？删除后不可恢复')
       if(r){
         this.$emit("operate", type, item, index);
       }
    }
  }
};
</script>
<style lang="less" scoped>
.order-item {
  border-bottom: 1px solid #cfcfcf; /*no*/
  font-size: 14px;
  .header {
    height: 40px;
    line-height: 40px;
    display: flex;
    background: #f5f5f5;
    color: #333;
    font-size: 14px;
  }
  .name {
    padding-left: 20px;
    width: 120px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .address {
    width: 375px;
  }
  .phone {
    flex: 1;
  }
  .operate {
    width: 180px;/* no */
    display: flex;
    justify-content: space-around;
    &:hover{
      cursor: pointer;
    }
    span {
      color: #931c22;
    }
    span:nth-child(1){
      width: 90px;/* no */
      text-align: center;
    }
  }
  .cotainer {
    padding-bottom: 100px;
    .addr-item {
      display: flex;
      height: 72px;
      border-bottom: 1px solid #cfcfcf; /*no*/
      align-items: center;
    }
  }
}
</style>
