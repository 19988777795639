<template>
  <el-dialog
    class="addr-dialog"
    title="收货地址"
    :visible.sync="dialogVisible"
    width="900"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="addr-item">
      <input class="name" type="text" placeholder="收货人姓名" v-model="addrInfo.name" />
      <input class="phone" type="text" placeholder="手机号码" v-model="addrInfo.mobile" />
    </div>
    <!-- <div class="addr-item">
      <input class="fixed-telephone" type="text" placeholder="固定电话"  v-model="addrInfo.name"/>
    </div>-->
    <div class="addr-item">
      <el-select
        class="select"
        v-model="provinceId"
        popper-class="addr-popper"
        placeholder="请选择所在省"
        @change="provinceChange"
        clearable
      >
        <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="cityId"
        popper-class="addr-popper"
        placeholder="请选择所在市"
        @change="cityChange"
        clearable
      >
        <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select
        class="select"
        v-model="areaId"
        popper-class="addr-popper"
        placeholder="请选择所在区县"
        @change="areaChange"
        clearable
      >
        <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
    </div>
    <div class="addr-item">
      <input class="phone" type="text" placeholder="街道编号/名称，楼宇名称" v-model="addrInfo.adress" />
    </div>
    <div class="default-addr">
      <i :class="{ selected: defaultAddr }" @click="selectDefault"></i>
      <span>设为默认地址</span>
    </div>
    <div class="footer">
      <div @click="cancel">取 消</div>
      <div type="primary" @click="confirm">确 定</div>
    </div>
  </el-dialog>
</template>

<script>
// @ is an alias to /src
import chinaCities from "../../../common/city";
import { EDIT_ADDRESS, SET_DEFAULT_ADDRESS } from "../../../service/address";

export default {
  name: "AddrDiolog",
  props: {
    visiable: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      defaultAddr: false,
      district: "",
      city: "",
      province: "",
      addrInfo: {
        takeoverid: "",
        name: "",
        city: "",
        cityid: "",
        mobile: "",
        adress: ""
      },
      provinceId: "",
      cityId: "",
      areaId: "",
      provinceList: chinaCities,
      cityList: [],
      areaList: []
    };
  },
  watch: {
    visiable: {
      immediate: true,
      handler(newValue) {
        this.dialogVisible = newValue;
      }
    },
    info: {
      immediate: true,
      handler(newValue) {
        if (!Object.keys(newValue).length) {
          this.reset();
          return;
        }
        let { id, name, city, cityid, mobile, adress } = newValue;
        this.addrInfo = { takeoverid: id, name, city, cityid, mobile, adress };
        this.returnData(newValue);
      }
    }
  },
  methods: {
    selectDefault() {
      this.defaultAddr = !this.defaultAddr;
      
    },
    handleClose() {
    //   if(this.addrInfo){
    //      this.$emit("update:visiable", true);
    //   }else{
    //      this.reset();
    //      this.$emit("update:visiable", false);
    //   }
    },
    cancel() {
      this.reset();
      this.$emit("update:visiable", false);
    },
    confirm() {
      let provinceName = this.provinceList.find(
        item => item.id === this.provinceId
      ).name;
      let cityName = this.cityList.find(item => item.id === this.cityId).name;
      let areaName = this.areaList.find(item => item.id === this.areaId).name;
      let city = provinceName + cityName + areaName;
      EDIT_ADDRESS({ ...this.addrInfo, city }).then(res => {
        if (res.status === 200) {
           this.reset();
           this.$emit("update:visiable", false);
          if (this.defaultAddr) {
            SET_DEFAULT_ADDRESS({ takeoverid: res.attachment.id });
          }
          this.$emit("confirm");
          this.$message({
            type: "success",
            message: "操作成功"
          });
        }else{
           this.$emit("update:visiable", true);
        }
      });
    },
    provinceChange(id) {
      this.cityList = [];
      this.cityId = "";
      this.cityChange();
      if (!id) {
        return;
      }
      this.cityList = this.provinceList.find(item => item.id === id).cities;
    },
    cityChange(id) {
      this.areaList = [];
      this.areaId = "";
      this.areaChange();
      if (!id) {
        return;
      }
      this.areaList = this.cityList.find(item => item.id === id).areas;
    },
    areaChange(id) {
      this.addrInfo.cityid = id;
    },
    returnData() {
      let province = this.provinceList.find(provice => {
        return this.findCity(provice);
      });
      this.cityList = province.cities;
      this.areaId = this.addrInfo.cityid;
    },
    findCity(provice) {
      let cityid = this.addrInfo.cityid;
      return provice.cities.some(city => {
        if (city.areas.some(area => area.id === cityid)) {
          this.provinceId = city.pid;
          this.cityId = city.id;
          this.areaList = city.areas;
          return true;
        }
      });
    },
    reset() {
      this.addrInfo = {};
      this.provinceId = "";
      this.cityId = "";
      this.areaId = "";
    }
  }
};
</script>
<style lang="less" scope>
.addr-dialog {
  .addr-item {
    display: flex;
    margin-bottom: 30px;
    > input {
      padding: 0 15px;
    }
    input {
      outline: none;
      height: 60px;
      border: 1px solid #cccccc; /*no*/
      border-radius: 0;
      margin-right: 20px;
      font-size: 18px;

      box-sizing: border-box;
    }
    input:last-child {
      margin-right: 0;
      flex: 1;
    }
    .select {
      margin-right: 20px;
      flex: 1;
    }
    .select:last-child {
      margin-right: 0;
    }
    .name {
      width: 300px;
    }
    input:-ms-input-placeholder {
      color: #999999;
    } /* Internet Explorer 10+ */

    input::-webkit-input-placeholder {
      color: #999999;
    } /* WebKit browsers */

    input::-moz-placeholder {
      color: #999999;
    } /* Mozilla Firefox 4 to 18 */

    input:-moz-placeholder {
      color: #999999;
    }
  }
  .default-addr {
    height: 36px;
    display: flex;
    align-items: center;
    i {
      width: 21px;
      height: 21px;
      background-image: url("../../../assets/images/uncheck-addr.png");
      background-size: 100% 100%;
      margin-right: 10px;
    }
    i.selected {
      background-image: url("../../../assets/images/check-addr.png");
      background-size: 100% 100%;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    div {
      width: 220px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      background: #921d23;
      color: #fff;
      font-size: 22px;
      cursor: pointer;
    }
    div:first-child {
      margin-right: 100px;
    }
  }
}
</style>
<style lang="less">
.addr-dialog {
  .el-dialog__header {
    padding: 20px 30px 60px;
  }
  .el-dialog__title {
    line-height: 30px;
    font-size: 22px;
  }
  .el-dialog__body {
    padding: 0 82px 82px;
  }
  .el-select .el-input__inner {
    height: 50px;
    font-size: 16px;
    border-radius: 0;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #ccc;
  }
  .el-select .el-input__inner:focus {
    border-color: #ccc;
  }
  .el-input__icon {
    line-height: 30px;
  }
}

.addr-popper {
  .el-select-dropdown__item.selected {
    color: #000;
  }
}
</style>
