<template>
  <div class="address">
    <div class="header">
      <divider-title text="收货地址" />
      <div class="no-data-label" v-show="!addressList.length">您还没有收货地址</div>
      <div class="add-data-label" v-show="addressList.length" @click="addAddress">
        <i class="el-icon-plus"></i>
        <span>添加新的收货地址</span>
      </div>
    </div>
    <div class="no-data-container">
      <div class="no-data" v-show="!addressList.length">
        <div class="go-irisgem">
          <span @click="addAddress">
            <i class="el-icon-plus"></i>
            <span>添加新的收货地址</span>
          </span>
        </div>
      </div>
    </div>
    <div class="container" v-show="addressList.length">
      <div class="list">
        <address-list :list="addressList" @operate="operate" @isclick='isclick'/>
      </div>
    </div>
    <addr-diolog :visiable.sync="addrDialog" :info="dialogAddr" @confirm="addrDiologConfirm" />
  </div>
</template>

<script>
// @ is an alias to /src
import dividerTitle from "@/components/dividerTitle";
import addressList from "./components/addressList";
import addrDiolog from "./components/addrDiolog";
import { GET_ADDRESSLIST, DEL_ADDRESS,SET_DEFAULT_ADDRESS } from "../../service/address";
export default {
  name: "Address",
  components: { dividerTitle, addressList, addrDiolog },
  data() {
    return {
      addrDialog: false,
      dialogAddr: {},
      addressList: []
    };
  },
  mounted() {
    this.getAddrList();
    // this.addressList = [];
  },
  methods: {
    isclick(id){
      SET_DEFAULT_ADDRESS({takeoverid:id}).then(()=>{
       this.getAddrList();
      })
    },
    getAddrList() {
      GET_ADDRESSLIST().then(res => {
        this.addressList = res.attachment.to;
      });
    },
    operate(...arg) {
      let type = arg[0];
      if (typeof this[type + "Addr"] === "function") {
        this[type + "Addr"](...arg.slice(1));
      }
    },
    editAddr(address, index) {
      console.log(address, index);
      this.addrDialog = true;
      this.dialogAddr = Object.assign({}, address);
    },
    deleteAddr(address, index) {
      this.addressList.splice(index, 1);
      DEL_ADDRESS({ takeoverid: address.id }).then(res => {
        if (res.status === 200) {
          this.$message({
            type: "success",
            message: "删除成功"
          });
        }
      });
    },
    addAddress() {
      this.addrDialog = true;
      this.dialogAddr = {};
      // console.log(this.dialogAddr)
    },
    addrDiologConfirm() {
      this.getAddrList();
    }
  }
};
</script>
<style lang="less" scoped>
.address {
  background: #f5f5f5;
  box-shadow: 0px 4px 5px 2px #d6d6d6;
  padding-bottom: 86px;
  .header {
    padding-top: 28px;
    height: 96px;
    background: #fff;
    .no-data-label {
      margin-left: 92px;
      font-size: 13px;
      color: #666;
      line-height: 20px;
      padding-top: 12px;
    }
  }
  .add-data-label {
    display: flex;
    font-size: 13px;
    line-height: 20px;
    padding-top: 12px;
    color: #941d21;
    align-items: center;
    justify-content: flex-end;
    cursor: default;
    margin-right: 56px;
    > i {
      width: 17px;
      height: 17px;
      line-height: 17px;
      text-align: center;
      margin-right: 4px;
      border: 1px solid #941d21; /*no*/
    }
  }
  // .no-data-container {
  //   background: #f5f5f5;
  .no-data {
    height: 420px;
    padding: 35px 72px 0;
    margin: 0 20px;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
    .go-irisgem {
      height: 82px;
      background: #f5f5f5;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        cursor: default;
        display: flex;
        font-size: 13px;
        line-height: 20px;
        color: #941d21;
        > i {
          width: 17px;
          height: 17px;
          line-height: 17px;
          text-align: center;
          margin-right: 4px;
          border: 1px solid #941d21; /*no*/
        }
      }
    }
  }
  // }

  .container {
    padding: 20px 20px 0;
  }
  .list {
    padding: 15px 20px 0;
    background: #fff;
    box-shadow: 0px 4px 5px 2px #d6d6d6;
  }
  .pagenation {
    background: #fff;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style lang="less">
.order {
  .el-input__inner {
    height: 30px;
    border-radius: 0;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #999999;
  }
  .el-select .el-input__inner:focus {
    border-color: #999999;
  }
  .el-input__icon {
    line-height: 30px;
  }
}
.order-popper {
  .el-select-dropdown__item.selected {
    color: #000;
    font-weight: 700;
  }
}
</style>
